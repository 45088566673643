.upload-btn {
  width: 5rem;
  height: 28px;
  margin-top: 6px;
}
.upload-btn /deep/ .el-upload {
  width: 5rem;
  height: 28px !important;
  border: none !important;
}
.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
